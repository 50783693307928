<template>
  <div class="task-item" @click="click">
  <!-- <div class="task-item" @click="click" :class="{disabled}"> -->
    <p class="task-item-name">{{name}}</p>
    <div style="display: flex; align-items: center; column-gap: 15px; margin-left: 15px;">
      <img :src="statusIcon" alt="status icon" />
      <!-- <img :src="disabled ? externalLinkDisabled : externalLink" alt="external link icon" /> -->
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import statuses from '@/data/statuses'
import externalLink from '@/assets/icons/external-link.svg'
import externalLinkDisabled from '@/assets/icons/external-link-disabled.png'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  status: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click'])

const statusIcon = computed(() => {
  const status = statuses.find(status => status.value === props.status)
  // return props.disabled ? status.iconDisabled ?? status.icon : status.icon
  return status.icon
})

const click = () => {
  if (!props.disabled) {
    emit('click')
  }
}
</script>

<style scoped>
.task-item {
  font-family: 'Helvetica Neue LT Std';
font-style: normal;
font-weight: 700;
font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f4f6f5;
  cursor: pointer;
}

/*.task-item-name {*/
/*  !* Check if this work in fillWidth (not adaptive) *!*/
/*  width: 300px;*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*}*/

.task-item:last-child {
  border-bottom: none;
}

.disabled {
  color: #c9ccce;
}
</style>
